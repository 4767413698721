<template>
  <vue-tel-input-vuetify
    v-model="phoneValue"
    flat
    outlined
    dense
    class=""
    mode="international"
    single-line
  >
  </vue-tel-input-vuetify>
</template>

<script>
import VueTelInputVuetify from "vue-tel-input-vuetify/lib/vue-tel-input-vuetify.vue"

export default {
  components: {
    VueTelInputVuetify
  },
  props:['value'],
  computed: {
    phoneValue: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit('input', val);
      }
    }
  },
}
</script>

<style>

</style>